import { Controller } from "@hotwired/stimulus";
import anx from "src/nextgen/Payments/anx";

class PaymentController extends Controller {
  static targets = ['submit', 'paymentElement', 'spinner', 'errorElement', 'parentModal'];

  connect() {
    this.parentModal = document.querySelector(`#${this.element.dataset.parentModal}`);
    this.connect = anx();

    let options = {
      mode: this.element.dataset.mode,
      currency: 'gbp',
      setupFutureUsage: this.element.dataset.setupFutureUsage,
      loader: 'always'
    }

    if (this.element.dataset.captureMethod) {
      options['captureMethod'] = this.element.dataset.captureMethod;
    }

    if (this.element.dataset.amount) {
      options['amount'] = Number(this.element.dataset.amount);
    }

    const elements = App.stripe.elements(options);
    const paymentElement = elements.create('payment', { terms: { card: 'never' } });
    this.parentModal.addEventListener('neo_modal:submitting_form', async(event) => {
      const { error } = await elements.submit();

      if (error) {
        // TODO: handle??
      } else {
        this.submitHandler(elements, 
                           event.detail.submitFunction, 
                           event.detail.errorFunction,
                           event.detail.successFunction);
      }
      
    });

    paymentElement.mount('#payment-element');
  }

  stripeAction() {
    if (this.element.dataset.mode == 'payment') {
      return 'confirmPayment';
    } else if (this.element.dataset.mode == 'setup') {
      return 'confirmSetup';
    }
  }

  async submitHandler(elements, submitFunction, errorFunction, successFunction) {
    submitFunction()
      .then(async (response) => {
        this.subscribe(response.data.modification.id, successFunction);
        Cookies.set('show_new_order_id', response.data.orderId);

        const clientSecret = response.data.modification.clientSecret;
        const {error} = await App.stripe[this.stripeAction()]({
          elements,
          clientSecret,
          confirmParams: { return_url: this.element.dataset.returnUrl },
          redirect: 'if_required'
        });

        if (error) {
          this.errorElementTarget.innerText = error.message;
        }
      })
      .catch((error) => errorFunction(error));
  }

  subscribe(modificationId, successCallback) {
    return this.connect.subscribe(modificationId, (data) => {
      switch (data.status) {
        case 'succeeded':
          this.handleSuccess(data, successCallback);
          break;
        case 'failed':
          this.handleFailed(data);
          break;
      }
    })
  }

  handleSuccess(data, successCallback) {
    if (data.message) {
      Helper.flash_message('success', data.message);
    }

    if (data.redirect_link) {
      window.location = data.redirect_link;
    }

    if (data.status == 'succeeded') {
      window.location.reload();
    }

    successCallback();
  }

  handleFailed(data) {
    Helper.flash_message('error', data.message)
  }
}

export default PaymentController;
